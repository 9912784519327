.rejectedcasesDetails-main {
  margin-top: 20px;
}

.opencase .card {
  background: linear-gradient(135deg, #0da44a 0%, #219c9c 77.06%);
  box-shadow: 0px 4px 5px rgba(26, 178, 117, 0.5);
}

.allottedcase .card {
  background: linear-gradient(135deg, #1ba2a2 0%, #0084ff 100%);
  box-shadow: 0px 4px 5px rgba(5, 138, 238, 0.5);
}

.reinvestigatecases .card {
  background: linear-gradient(135deg, #da7934 0%, #edb901 100%);
  box-shadow: 0px 4px 5px rgba(198, 155, 27, 0.5);
}

.rejectedcasesDetails-main .card {
  margin: 0 13px 8px 12px;
  background: linear-gradient(121.96deg, #8b1453 0%, #561437 63.64%);
  box-shadow: 0px 4px 5px rgba(124, 20, 75, 0.5);
  border-radius: 10px;
}

.AcceptButtons-ContainerParent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AcceptButtons-Container {
  z-index: 1;
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 65px;
  width: 100%;
}
.rejected-cases-details-page {
  margin-bottom: 150px;
}
.rejected-cases-details-page .navbar-container {
  box-shadow: 0px 0px 4px rgba(124, 20, 75, 0.25);
}
.rejected-cases-details-page .AcceptButtonsBtn {
  width: auto;
}
.rejected-cases-details-page .investgation-header {
  font-style: normal;
  font-weight: 500;
  font-size: var(--lg-text);
  line-height: 17px;
  color: #3a3a3a;
  padding: 7px 0px 6px 0px;
  margin: 10px 12px 0px;
}
.rejected-cases-details-page .AssignBlocksFormBottom {
  margin-bottom: 10px;
}

.previous-invoices-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.new-invoices-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delete-img {
  width: 17px;
  height: 17px;
  margin: 10px 13px 0 0;
}
